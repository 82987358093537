@import "~antd/dist/antd.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

/* Overrides */

/* Make select fields fill the column width */
.ant-table-header-column {
  display: block;
  width: 100%;
}
.ant-select {
  width: 100%;
}

/* Make select dropdown display more options */
.ant-select-dropdown-menu {
  max-height: 500px;
}

/* Make each step clickable */
.ant-steps-item-container {
  cursor: pointer;
}

.ant-descriptions-item-label {
  text-align: left;
  width: 150px;
}
